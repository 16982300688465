<template>
  <div>
    <b-card>
      <b-card-header
        style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
      >
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4><b style="color: cornflowerblue">Tambah Bimbel</b></h4>
          </b-card-title>
        </div>
        <b-nav>
          <b-nav-item>
            <router-link to="/admin/bimbel">
              <b-button variant="primary">
                <feather-icon icon="ArrowLeftIcon" style="color: white" />
                <span class="align-right"
                  ><b style="color: white"> Kembali</b></span
                >
              </b-button>
            </router-link>
          </b-nav-item>
        </b-nav>
      </b-card-header>
      <b-form
        method="post"
        enctype="multipart/form-data"
        @submit.prevent="addData()"
      >
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Judul</b></label>
            <b-form-input id="title" v-model="title" placeholder="Judul" />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default">Deskripsi</label>
            <b-form-textarea
              id="textarea-default"
              placeholder="Deskripsi"
              v-model="description"
              rows="2"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Durasi (Menit)</b></label>
            <b-form-input id="title" v-model="content" placeholder="Durasi" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Upload Photo</b></label>
            <b-form-group>
              <b-form-file
                v-if="uploadReady"
                ref="file"
                type="file"
                class="form-control"
                name="file"
                v-on:change="fileUpload($event.target)"
              />
            </b-form-group>
            <b-progress
              v-if="isLoading"
              animated
              :value="progressBar"
              :style="{ width: progressBar + '%' }"
              show-value
              :class="'progress-bar-info'"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Upload Video</b></label>
            <b-form-group>
              <b-form-file
                v-if="uploadReady"
                ref="file"
                type="file"
                class="form-control"
                name="video"
                v-on:change="videoUpload($event.target)"
              />
            </b-form-group>
            <b-progress
              v-if="isLoading"
              animated
              :value="progressBar"
              :style="{ width: progressBar + '%' }"
              show-value
              :class="'progress-bar-info'"
            />
          </b-col>
        </b-row>

        <b-col md="6" xl="1" class="mb-1">
          <b-form-input
            hidden
            id="basicInput"
            v-model="hidden_file"
            placeholder="Name File"
          />
        </b-col>
        <b-col md="6" xl="1" class="mb-1">
          <b-form-input
            hidden
            id="basicInput"
            v-model="hidden_video"
            placeholder="Name Video"
          />
        </b-col>
        <b-row>
          <b-col md="6" xl="4" class="mb-1" />
          <b-col md="6" xl="2" class="mb-1">
            <b-button variant="success" style="width: 100%" type="submit">
              <feather-icon icon="CheckCircleIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Simpan</b></span
              >
            </b-button>
          </b-col>
          <b-col md="6" xl="2" class="mb-1">
            <b-button
              variant="danger"
              style="width: 100%"
              type="button"
              @click="cancelButton('info')"
            >
              <feather-icon icon="XCircleIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Batal</b></span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BFormFile,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BButton,
  BForm,
  BFormInput,
  BToast,
  BCardHeader,
  BNav,
  BCardTitle,
  BFormSelect,
  BNavItem,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCard,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BFormInput,
    quillEditor,
    BForm,
    BFormFile,
    BTh,
    BToast,
    BTd,
    ToastificationContent,
    BTbody,
    Swal,
    BTfoot,
    BButton,
    BCardHeader,
    BNav,
    BFormTextarea,
    BNavItem,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      bimbel: [],
      id: this.$route.params.id,
      title: "",
      description: "",
      content: "",
      photo: "",
      file: null,
      video: null,
      isEdit: "Tidak",
      isLoading: false,
      progressBar: 0,
      uploadReady: true,
      files: [],
      videos: [],
      hidden_file: "",
      hidden_video: "",
    };
  },
  mounted(){
    this.checkAuth();
  },
  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      var levelCheck = localStorage.getItem("Ulevel");
      if (sessionCheck == null || sessionCheck == "") {
                    this.$router.push({ name: "login" });
      }
      else if(levelCheck != 2){
            this.$router.push({ name: "dashboard" });
      }
    },
    async addData() {
      this.isLoading = true;
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("video", this.video);
      formData.append("title", this.title);
      formData.append("description", this.description);
      formData.append("content", this.content);
      formData.append("photo", this.photo);
      formData.append("hidden_file", this.hidden_file);
      formData.append("hidden_video", this.hidden_video);
      await Swal.fire({
        title: "Apa sudah selesai?",
        text: "Perubahan data disesuaikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, sudah selesai!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(
                process.env.VUE_APP_BACKEND_URL + `bimbels/create`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: function (progressEvent) {
                    this.progressBar = parseInt(
                      Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                    );
                  }.bind(this),
                }
              )
              .then((result) => {
                this.file = "";
                this.title = "";
                this.description = "";
                this.content = "";
                this.photo = "";
                this.video = "";
                this.isEdit = "Tidak";
                this.hidden_file = null;
                this.$router.push({ name: "list_bimbel" });
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: "Notification",
                      icon: "CheckCircleIcon",
                      text: "Simpan Perubahan Data",
                      variant: "success",
                    },
                  },
                  {
                    timeout: 5000,
                  },
                  {
                    position: "top-right",
                  }
                );
                setTimeout(() => {
                  this.photoReset();
                  this.videoReset();
                  this.isLoading = false;
                  this.progressBar = 0;
                }, 2000);
              });
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "CheckCircleIcon",
                  text: "Batal Perubahan Data",
                  variant: "warning",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          }
        })
        .then((result) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    cancelButton() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "CheckCircleIcon",
            text: "Batal Ubah Data",
            variant: "info",
          },
        },
        {
          timeout: 5000,
        },
        {
          position: "top-right",
        }
      );
      this.$router.push({ name: "list_bimbel" });
      setTimeout(() => {
        this.photoReset();
        this.videoReset();
        this.isLoading = false;
        this.progressBar = 0;
      }, 2000);
    },
    fileUpload(event) {
      this.file = event.files[0];
    },
    videoUpload(event) {
      this.video = event.files[0];
    },
    photoReset() {
      this.uploadReady = false;
      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },
    videoReset() {
      this.uploadReady = false;
      this.$nextTick(() => {
        this.uploadReady = true;
      });
    },
  },
};
</script>
